
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import * as Yup from "yup";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import { useRoute } from "vue-router";
import { PlatformConfigsModel } from "@/store/models/shared/config/PlatformConfigsModel";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";

export default defineComponent({
    name: "SGSignIn",
    components: {
        Field,
        Form,
        ErrorMessage
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const queryDataId = route.fullPath.substring(route.fullPath.indexOf("=") + 1);

        if (!route.query.id) {
            redirectToRouteWithName("sign-in");
            return;
        }
        let errorMsgVisible = ref(false);

        const submitButton = ref<HTMLButtonElement | null>(null);

        const platformConfigs = new PlatformConfigsModel(PlatformTypeEnum.BANK);

        //Create form validation object
        const checkEmail = Yup.object().shape({
            email: Yup.string().email().required().label("Email")
        });

        const onSubmitCheckEmail = async (values) => {
            if (submitButton.value) {
                // eslint-disable-next-line
                submitButton.value!.disabled = true;
                // Activate indicator
                submitButton.value.setAttribute("data-kt-indicator", "on");
            }

            store.dispatch(Actions.CHANGE_EMAIL, {
                email: values.email,
                data_id: decodeURIComponent(queryDataId)
            })
                .then(() => {
                    redirectToRouteWithName("create-password");
                })
                .finally(() => {
                    //Deactivate indicator
                    submitButton.value?.removeAttribute("data-kt-indicator");
                    // eslint-disable-next-line
                    submitButton.value!.disabled = false;
                });

        };

        return {
            onSubmitCheckEmail,
            checkEmail,
            submitButton,
            errorMsgVisible,
            platformConfigs
        };
    }
});
